const URI = {
  original: {
    "slider-original": "slider/original",
    "product-original": "product/original",
    "shop-original": "shop/original",
    profile: "profile/original",
  },
  category: "product_category",
};

export default URI;
