enum ActionTypes {
  GET_CARTS_REQUEST = "GET_CARTS_REQUEST",
  GET_CARTS_SUCCESS = "GET_CARTS_SUCCESS",
  GET_CARTS_FAILURE = "GET_CARTS_FAILURE",

  ADD_CART_REQUEST = "ADD_CART_REQUEST",
  ADD_CART_SUCCESS = "ADD_CART_SUCCESS",
  ADD_CART_FAILURE = "ADD_CART_FAILURE",

  UPDATE_CART_REQUEST = "UPDATE_CART_REQUEST",
  UPDATE_CART_SUCCESS = "UPDATE_CART_SUCCESS",
  UPDATE_CART_FAILURE = "UPDATE_CART_FAILURE",

  DEL_CART_REQUEST = "DEL_CART_REQUEST",
  DEL_CART_SUCCESS = "DEL_CART_SUCCESS",
  DEL_CART_FAILURE = "DEL_CART_FAILURE",
}

export default ActionTypes;
