import ActionTypes from "../actions/sliders/actionTypes";
import { SlidersHome } from "../../configs/datatype";

interface SlidersReducer {
  sliders: Array<SlidersHome>;
}

interface ClearState {
  type: "CLEAR_SLIDERS";
  payload: SlidersReducer;
}

interface GetSlidersHomeAction {
  type: ActionTypes.GET_SLIDERS_SUCCESS;
  payload: {
    data: SlidersReducer["sliders"];
  };
}

type SlidersAction = ClearState | GetSlidersHomeAction;

const initialState: SlidersReducer = {
  sliders: [],
};

const slidersReducer = (state: SlidersReducer = initialState, action: SlidersAction) => {
  switch (action.type) {
    case ActionTypes.GET_SLIDERS_SUCCESS:
      return {
        ...state,
        sliders: action.payload.data,
      };
    case "CLEAR_SLIDERS":
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default slidersReducer;
