import ActionTypes from "../actions/chats/actionTypes";
import { uri } from "../../configs";
import { ConversationsData, ConversationData, MessagesAttributes, MessagesData } from "../../configs/datatype";

interface ChatsReducer {
  isOpenChat: boolean;
  isOpenMessage: boolean;
  conversations: Array<ConversationsData>;
  conversation: Partial<ConversationData>;
  messages: Array<MessagesAttributes>;
  messagesCursor: number;
  messagesHasMore: boolean;
}

interface ChatActions {
  type: string;
  payload: {
    data?: ChatsReducer;
    message?: string;
  } | any;
}

const initialState: ChatsReducer = {
  isOpenChat: false,
  isOpenMessage: false,
  conversations: [],
  conversation: {},
  messages: [],
  messagesCursor: 0,
  messagesHasMore: false,
};

const chatsReducer = (state: ChatsReducer = initialState, action: ChatActions) => {
  switch (action.type) {
    case ActionTypes.GET_CONVERSATION_SUCCESS: {
      const { conversations } = action.payload.data;

      const assetURL = `${process.env.NEXT_PUBLIC_GRAHA_ASSET}/${uri.original.profile}`;

      for (let i = 0; i < conversations.length; i += 1) {
        conversations[i].attributes.participants.avatar = `${assetURL}/${conversations[i].attributes.participants.avatar}`;
      }

      return {
        ...state,
        conversations,
      };
    }
    case ActionTypes.ADD_CONVERSATION_SUCCESS:
      return {
        ...state,
        conversation: action.payload.data.conversation,
      };
    case ActionTypes.GET_MESSAGE_SUCCESS: {
      // const messages: Array<MessagesAttributes> = [...state.messages, ...action.payload.data.messages];

      return {
        ...state,
        isOpenMessage: true,
        messages: [...action.payload.data.messages, ...state.messages],
        messagesCursor: action.payload.data.messagesCursor,
        messagesHasMore: action.payload.data.messagesHasMore,
      };
    }
    case "NEW_MESSAGES":
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    case "CLEAR_MESSAGES":
      return {
        ...state,
        messages: [],
        conversation: {},
      };
    case "OPEN_CHAT":
      return {
        ...state,
        isOpenChat: true,
      };
    case "CLOSE_CHAT":
      return {
        ...state,
        isOpenChat: false,
      };
    case "CLEAR_CHAT":
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default chatsReducer;
