enum ActionTypes {
  ADD_CONVERSATION_REQUEST = "ADD_CONVERSATION_REQUEST",
  ADD_CONVERSATION_SUCCESS = "ADD_CONVERSATION_SUCCESS",
  ADD_CONVERSATION_FAILURE = "ADD_CONVERSATION_FAILURE",

  GET_CONVERSATION_REQUEST = "GET_CONVERSATION_REQUEST",
  GET_CONVERSATION_SUCCESS = "GET_CONVERSATION_SUCCESS",
  GET_CONVERSATION_FAILURE = "GET_CONVERSATION_FAILURE",

  ADD_MESSAGE_REQUEST = "ADD_MESSAGE_REQUEST",
  ADD_MESSAGE_SUCCESS = "ADD_MESSAGE_SUCCESS",
  ADD_MESSAGE_FAILURE = "ADD_MESSAGE_FAILURE",

  GET_MESSAGE_REQUEST = "GET_MESSAGE_REQUEST",
  GET_MESSAGE_SUCCESS = "GET_MESSAGE_SUCCESS",
  GET_MESSAGE_FAILURE = "GET_MESSAGE_FAILURE",
}

export default ActionTypes;
