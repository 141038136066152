import ActionTypes from "./actionTypes";
import { serviceAddConversations, serviceAddMessages, serviceGetConversations, serviceGetMessages } from "../../../services";
import { AppDispatch } from "../../store";
// Utils
import { decoded } from "../../../utils";
// Configs
import * as DataType from "../../../configs/datatype";
import { UserProfile } from "../../../configs/datatype";

export const actionGetMessages = (params: DataType.MessagesParams, isInitial: boolean) => {
  return async (dispatch: AppDispatch) => {
    dispatch({ type: ActionTypes.GET_MESSAGE_REQUEST });

    try {
      const response: DataType.MessagesResponse = await serviceGetMessages(params);

      const messages: Array<DataType.MessagesAttributes> = response.data!.map((val: DataType.MessagesData) => {
        return {
          conversation_id: val.attributes.conversation_id,
          sender: val.attributes.sender,
          content: val.attributes.content,
          is_received: val.attributes.is_received,
          created_at: val.attributes.created_at,
        };
      });

      const messagesCursor: number = response.data.length > 0 ? response.meta.cursor?.last_records! : 0;

      return dispatch({
        type: ActionTypes.GET_MESSAGE_SUCCESS,
        payload: {
          data: {
            messages: messages.reverse(),
            messagesCursor,
            messagesHasMore: response.meta.total_records! > response.meta.limit!,
          },
          message: "",
        },
      });
    } catch (err: any) {
      return dispatch({
        type: ActionTypes.GET_MESSAGE_FAILURE,
        payload: {
          data: false,
          message: err.message,
        },
      });
    }
  };
};

export const actionAddMessages = (data: DataType.MessagesAdd) => {
  return async (dispatch: AppDispatch) => {
    dispatch({ type: ActionTypes.ADD_MESSAGE_REQUEST });

    try {
      const response = await serviceAddMessages(data);

      return dispatch({
        type: ActionTypes.ADD_MESSAGE_SUCCESS,
        payload: {
          data: null,
          message: "",
        },
      });
    } catch (err: any) {
      return dispatch({
        type: ActionTypes.ADD_MESSAGE_FAILURE,
        payload: {
          data: false,
          message: err.message,
        },
      });
    }
  };
};

export const actionGetConversation = (params: DataType.ConversationsParams) => {
  return async (dispatch: AppDispatch) => {
    dispatch({ type: ActionTypes.GET_CONVERSATION_REQUEST });

    try {
      const response: DataType.ConversationsResponse = await serviceGetConversations(params);

      return dispatch({
        type: ActionTypes.GET_CONVERSATION_SUCCESS,
        payload: {
          data: { conversations: response.data },
          message: "",
        },
      });
    } catch (err: any) {
      return dispatch({
        type: ActionTypes.GET_CONVERSATION_FAILURE,
        payload: {
          data: false,
          message: err.message,
        },
      });
    }
  };
};

export const actionAddConversation = (data: DataType.ConversationsAdd, params: DataType.ConversationsParams) => {
  return async (dispatch: AppDispatch) => {
    dispatch({ type: ActionTypes.ADD_CONVERSATION_REQUEST });

    try {
      const user: Partial<UserProfile> = localStorage.getItem("ufk") ? JSON.parse(decoded(localStorage.getItem("ufk")!)) : {};

      const response: DataType.ConversationResponse = await serviceAddConversations(data);

      dispatch(actionGetMessages({ limit: 10, conversation: response.data.id, user_id: user?.id! }, true));

      dispatch(actionGetConversation(params));

      return dispatch({
        type: ActionTypes.ADD_CONVERSATION_SUCCESS,
        payload: {
          data: { conversation: response.data },
          message: "",
        },
      });
    } catch (err: any) {
      return dispatch({
        type: ActionTypes.ADD_CONVERSATION_FAILURE,
        payload: {
          data: false,
          message: err.message,
        },
      });
    }
  };
};
