import { API_GOOGLEMAP, API_MAPBOX, API_GOOGLESTORAGE } from "./http";
import { __exgo } from "../configs/constants";
import { decoded } from "../utils";
import { Locations, LocationType, PlaceLocations } from "../configs/datatype";

const __xCo = decoded(__exgo);

export const serviceGetLocationByMapbox = async (location: any, type: LocationType) => {
  let endpoint = `/geocoding/v5/mapbox.places/${location.lng},${location.lat}.json`;

  if (type === "address") endpoint = `/geocoding/v5/mapbox.places/${location.search_text}.json`;

  const request = await API_MAPBOX.get(endpoint, {
    params: {
      language: "id",
      country: "id",
    },
  });

  const data: Locations = {
    address: "",
    coordinate: {
      latitude: 0,
      longitude: 0,
    },
    kelurahan: "",
    kecamatan: "",
    kabupaten: "",
    provinsi: "",
  };

  // const data: any = {
  //   address: val.place_name,
  //   cordinate: { latitude: request.data.query[0], longitude: request.data.request[1] },
  //   provinsi: provinsi.replace("Special Region of ", "D.I."),
  // };

  for (let i = 0; i < request.data.features[0].context.length; i += 1) {
    const cityAdministration = request.data.features[0].context[i].id.split(".")[0];

    request.data.features[0].context[i].id = cityAdministration;
  }

  data.address = request.data.features[0]?.text || "";
  data.coordinate = {
    longitude: request.data.features[0].center[0],
    latitude: request.data.features[0].center[1],
  };
  data.kelurahan = request.data.features[0].context.find((val: any) => { return val.id === "neighborhood"; })?.text || "";
  data.kecamatan = request.data.features[0].context.find((val: any) => { return val.id === "locality"; })?.text || "";
  data.kabupaten = request.data.features[0].context.find((val: any) => { return val.id === "place"; })?.text || "";

  const provinsi = request.data.features[0].context.find((val: any) => { return val.id === "region"; })?.text;

  data.provinsi = provinsi.replace("Special Region of", "D.I. ") || "";

  return data;
};

export const serviceGetLocationListByMapbox = async (keywords: string) => {
  const endpoint = `/geocoding/v5/mapbox.places/${keywords}.json`;

  const request = await API_MAPBOX.get(endpoint, {
    params: {
      language: "id",
      country: "id",
      // types: "country,region,place,postcode,locality,neighborhood",
      limit: 5,
    },
  });

  const data: Array<PlaceLocations> = request.data.features.map((item: any, i: number) => {
    const features = { ...item };

    const temp: PlaceLocations = {
      title: features.text,
      place: features.place_name,
      // geometry: features.geometry,
      location: {
        address: "",
        coordinate: {
          latitude: 0,
          longitude: 0,
        },
        kelurahan: "",
        kecamatan: "",
        kabupaten: "",
        provinsi: "",
      },
    };

    for (let x = 0; x < features.context.length; x += 1) {
      const cityAdministration = features.context[x].id.split(".")[0];

      features.context[x].id = cityAdministration;
    }

    temp.location!.address = features?.text || "";
    temp.location!.coordinate = {
      longitude: features.center[0],
      latitude: features.center[1],
    };
    temp.location!.kelurahan = features.context.find((location: any) => { return location.id === "neighborhood"; })?.text || "";
    temp.location!.kecamatan = features.context.find((location: any) => { return location.id === "locality"; })?.text || "";
    temp.location!.kabupaten = features.context.find((location: any) => { return location.id === "place"; })?.text || "";
    temp.location!.provinsi = features.context.find((location: any) => { return location.id === "region"; })?.text || "";

    return temp;
  });

  return data;
};

export const serviceGetLocationByGoogle = async (location: any, type: LocationType) => {
  // const endpoint = `/geocode/json?${type}=${location}&key=${__xCo}&language=ID`;
  const endpoint = "/geocode/json";

  const request = await API_GOOGLEMAP.get(endpoint, {
    params: {
      [type]: location,
      key: __xCo,
      language: "id",
    },
  });

  const data: Locations = {
    address: "",
    coordinate: {
      latitude: 0,
      longitude: 0,
    },
    kelurahan: "",
    kecamatan: "",
    kabupaten: "",
    provinsi: "",
  };

  const provinsi = request.data.results[0].address_components.filter((o: any) => { return o.types.includes("administrative_area_level_1"); });
  const kabupaten = request.data.results[0].address_components.filter((o: any) => { return o.types.includes("administrative_area_level_2"); });
  const kecamatan = request.data.results[0].address_components.filter((o: any) => { return o.types.includes("administrative_area_level_3"); });
  const kelurahan = request.data.results[0].address_components.filter((o: any) => { return o.types.includes("administrative_area_level_4"); });

  data.address = request.data.results[0].formatted_address;

  data.coordinate = {
    latitude: request.data.results[0].geometry.location.lat,
    longitude: request.data.results[0].geometry.location.lng,
  };

  data.provinsi = provinsi[0].long_name.replace("Daerah Istimewa", "D.I.");

  if (kabupaten.length > 0) {
    if (kabupaten[0].long_name.split(" ").length > 1) {
      const regency = kabupaten[0].long_name.split(" ")[1];
      data.kabupaten = regency;
    } else {
      data.kabupaten = kabupaten[0].long_name;
    }
  } else {
    data.kabupaten = "";
  }

  if (kecamatan.length > 0) {
    if (kecamatan[0].long_name.split(" ").length > 1) {
      const district = kecamatan[0].long_name.split(" ")[1];
      data.kecamatan = district;
    } else {
      data.kecamatan = kecamatan[0].long_name;
    }
  } else {
    data.kecamatan = "";
  }

  if (kelurahan.length > 0) {
    if (kelurahan[0].long_name.split(" ").length > 1) {
      const subdistrict = kelurahan[0].long_name.split(" ")[1];
      data.kelurahan = subdistrict;
    } else {
      data.kelurahan = kelurahan[0].long_name;
    }
  } else {
    data.kelurahan = "";
  }

  return data;
};

export const serviceGetLocationListByGoogle = async (keywords: string, location: string) => {
  const endpoint = "/place/autocomplete/json";

  const request = await API_GOOGLEMAP.get(endpoint, {
    params: {
      input: keywords,
      key: __xCo,
      components: "country:id",
      language: "id",
      location,
      radius: 10000,
      strictbounds: true,
    },
  });

  const data: Array<PlaceLocations> = request.data.predictions.map((item: any, i: number) => {
    // const features = { ...item };

    const temp: PlaceLocations = {
      title: item.structured_formatting.main_text,
      place: item.description,
    };

    return temp;
  });

  return data;
};

export const serviceGetFaq = async () => {
  const endpoint = "foodizmart-b12e1.appspot.com/o/CONTENT_16042022_FAQ_1_0.json";

  const request = await API_GOOGLESTORAGE.get(endpoint, {
    params: {
      alt: "media",
      // token: "0c74c9ea-0611-4586-90e0-dbcd70fecd79",
    },
  });

  const response = request.data;

  return response;
};

export const serviceGetWithdrawTerm = async () => {
  const endpoint = "foodizmart-b12e1.appspot.com/o/CONTENT_18042022_WithdrawTerm_1_0.json";

  const request = await API_GOOGLESTORAGE.get(endpoint, {
    params: {
      alt: "media",
      // token: "0c74c9ea-0611-4586-90e0-dbcd70fecd79",
    },
  });

  const response = request.data;

  return response;
};
