import { combineReducers } from "redux";
import ActionTypes from "../actions/users/actionTypes";

import rdcusers from "./users";
import rdcsliders from "./sliders";
import rdcdashboard from "./dashboard";
import rdccarts from "./carts";
import rdcaddress from "./address";
import rdcchats from "./chats";
import loading from "./loading";
import error from "./error";

const appReducer = combineReducers({
  /* top-level reducers */
  rdcusers,
  rdcsliders,
  rdcdashboard,
  rdccarts,
  rdcaddress,
  rdcchats,
  loading,
  error,
});

const rootReducer = (state: any, action: any) => {
  let globalState = state;

  if (action.type === ActionTypes.LOGOUT_USER_SUCCESS) {
    globalState = undefined;
  }

  // Persist
  // if (action.type === ActionTypes.LOGOUT_USER_REQUEST) {
  //   // for all keys defined in your persistConfig(s)
  //   storage.removeItem("persist:root");
  //   // storage.removeItem('persist:otherKey')

  //   globalState = undefined;
  // }

  return appReducer(globalState, action);
};

// const rootReducer = appReducer;

export default rootReducer;
