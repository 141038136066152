import ActionTypes from "../actions/dashboard/actionTypes";
import { Categories, Products, Shops, AssetsUri } from "../../configs/datatype";

interface DashboardReducer {
  categories: Array<Categories>;
  productsNearby: Array<Products>;
  shopsNearby: Array<Shops>;
  helper: Partial<AssetsUri>;
}

interface DashboardAction {
  type: string;
  payload: {
    data: DashboardReducer
    message?: string;
  };
}

const initialState: DashboardReducer = {
  categories: [],
  productsNearby: [],
  shopsNearby: [],
  helper: {},
};

const dashboardReducer = (state: DashboardReducer = initialState, action: DashboardAction) => {
  switch (action.type) {
    case ActionTypes.GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        categories: action.payload.data.categories,
        productsNearby: action.payload.data.productsNearby,
        shopsNearby: action.payload.data.shopsNearby,
        helper: action.payload.data.helper,
      };
    case "CLEAR_DASHBOARD":
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
