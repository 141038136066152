import FormData from "form-data";
import ActionTypes from "./actionTypes";
import { serviceGetAddresses } from "../../../services";
import { AppDispatch } from "../../store";

export const actionGetAddresses = (reqdata: any) => {
  return async (dispatch: AppDispatch) => {
    dispatch({ type: ActionTypes.GET_ADDRESS_LISTS_REQUEST });

    try {
      const fd = new FormData();
      fd.append("session_request", reqdata.token);

      const response = await serviceGetAddresses(fd);

      return dispatch({
        type: ActionTypes.GET_ADDRESS_LISTS_SUCCESS,
        payload: { data: response.data.address_list },
      });
    } catch (err: any) {
      return dispatch({
        type: ActionTypes.GET_ADDRESS_LISTS_FAILURE,
        payload: {
          data: false,
          message: err.message,
        },
      });
    }
  };
};

export const actionAddAddress = () => {
  return async (dispatch: AppDispatch) => {
    dispatch({ type: ActionTypes.ADD_ADDRESS_REQUEST });

    try {
      return dispatch({
        type: ActionTypes.ADD_ADDRESS_SUCCESS,
        payload: null,
      });
    } catch (err: any) {
      return dispatch({
        type: ActionTypes.ADD_ADDRESS_FAILURE,
        payload: {
          data: false,
          message: err.message,
        },
      });
    }
  };
};
