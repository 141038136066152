import ActionTypes from "../actions/carts/actionTypes";
import { CartsItem } from "../../configs/datatype";

interface CartsReducer {
  cartsId: string;
  carts: Array<CartsItem>;
  paymentAvailable: Array<any>;
  isLoadingCart: boolean;
  isLoadingItem: boolean;
}

interface CartsAction {
  type: string;
  payload: {
    data: CartsReducer
    message: string;
  };
}

const initialState: CartsReducer = {
  cartsId: "",
  carts: [],
  paymentAvailable: [],
  isLoadingCart: false,
  isLoadingItem: false,
};

const cartsReducer = (state: CartsReducer = initialState, action: CartsAction) => {
  switch (action.type) {
    case ActionTypes.GET_CARTS_REQUEST:
    case ActionTypes.ADD_CART_REQUEST:
      return {
        ...state,
        isLoadingCart: true,
      };
    case ActionTypes.UPDATE_CART_REQUEST:
    case ActionTypes.DEL_CART_REQUEST:
      return {
        ...state,
        isLoadingItem: true,
      };
    case ActionTypes.GET_CARTS_FAILURE:
    case ActionTypes.ADD_CART_FAILURE:
      return {
        ...state,
        isLoadingCart: false,
      };
    case ActionTypes.UPDATE_CART_FAILURE:
    case ActionTypes.DEL_CART_FAILURE:
      return {
        ...state,
        isLoadingItem: false,
      };
    case ActionTypes.GET_CARTS_SUCCESS:
    case ActionTypes.ADD_CART_SUCCESS:
      return {
        ...state,
        cartsId: action.payload.data.cartsId,
        carts: action.payload.data.carts,
        paymentAvailable: action.payload.data.paymentAvailable,
        isLoadingCart: false,
      };
    case ActionTypes.UPDATE_CART_SUCCESS:
    case ActionTypes.DEL_CART_SUCCESS:
      return {
        ...state,
        carts: action.payload.data.carts,
        isLoadingItem: false,
      };
    case "ADD_TO_STATE_CARTS":
      return {
        ...state,
        carts: action.payload.data.carts,
      };
    case "CLEAR_CARTS":
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default cartsReducer;
