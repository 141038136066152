import { useState, useEffect } from "react";
import { toast } from "react-toastify";

const useGeoLocation = () => {
  const [accuracy, setAccuracy] = useState<number>(0);
  const [location, setLocation] = useState({
    loaded: false,
    coordinates: { lat: 0, lng: 0 },
  });

  const options: PositionOptions = { enableHighAccuracy: true, maximumAge: 2000, timeout: 5000 };

  const onSuccess = (loc: GeolocationPosition) => {
    setLocation({
      loaded: true,
      coordinates: {
        lat: loc.coords.latitude,
        lng: loc.coords.longitude,
      },
    });
  };

  const onError = (error: Partial<GeolocationPositionError>) => {
    if (error.code === 1) {
      setLocation({
        loaded: true,
        coordinates: {
          lat: -7.755802099999999,
          lng: 110.3993969,
        },
      });
    }

    if (process.env.NEXT_PUBLIC_ENV === "development") toast.error(error.message);
  };

  useEffect(() => {
    if (!("geolocation" in navigator)) {
      onError({
        code: 0,
        message: "Geolocation not supported",
      });
    }

    navigator.geolocation.getCurrentPosition(onSuccess, onError, options);
    // const geoId = navigator.geolocation.watchPosition(onSuccess, onError, options);
  }, []);

  return location;
};

export default useGeoLocation;
