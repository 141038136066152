import { useState, useEffect } from "react";
import { toast } from "react-toastify";

export default function useLocalStorage(key: string, initialValue: any) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = localStorage.getItem(key);

      return item ? JSON.parse(item) : initialValue;
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") toast.error(err.message);
      return initialValue;
    }
  });

  useEffect(() => {
    const item = localStorage.getItem(key);

    setStoredValue(item ? JSON.parse(item) : initialValue);
  }, []);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: (store: any) => void) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;

      setStoredValue(valueToStore);

      localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (err: any) {
      if (process.env.NEXT_PUBLIC_ENV === "development") toast.error(err.message);
    }
  };

  return [storedValue, setValue];
}
