import ActionTypes from "../actions/users/actionTypes";
import { UserProfile } from "../../configs/datatype";

interface UsersReducer {
  actionsType: string;
  loading: boolean;
  isLogin: boolean;
  user: Partial<UserProfile>;
  token: string;
}

interface ClearState {
  type: ActionTypes.LOGOUT_USER_SUCCESS | "CLEAR_USER_DATA";
  payload: UsersReducer;
}

interface LoadingState {
  type: ActionTypes.REGISTER_USER_REQUEST
        | ActionTypes.LOGIN_USER_REQUEST
        | ActionTypes.LOGOUT_USER_REQUEST
        | ActionTypes.GET_USER_PROFILE_REQUEST
        | ActionTypes.UPDATE_USER_PROFILE_REQUEST;
}

interface FailureState {
  type: ActionTypes.REGISTER_USER_FAILURE
        | ActionTypes.LOGIN_USER_FAILURE
        | ActionTypes.LOGOUT_USER_FAILURE
        | ActionTypes.GET_USER_PROFILE_FAILURE
        | ActionTypes.UPDATE_USER_PROFILE_FAILURE;
}

interface ManageUserAction {
  type: ActionTypes.REGISTER_USER_SUCCESS | ActionTypes.LOGIN_USER_SUCCESS | ActionTypes.GET_USER_PROFILE_SUCCESS | ActionTypes.UPDATE_USER_PROFILE_SUCCESS;
  payload: {
    data: UsersReducer["user"];
    token: UsersReducer["token"];
  };
}

type UsersAction = ClearState | LoadingState | FailureState | ManageUserAction;

const initialState: UsersReducer = {
  actionsType: "",
  loading: false,
  isLogin: false,
  user: {},
  token: "",
};

const usersReducer = (state: UsersReducer = initialState, action: UsersAction) => {
  switch (action.type) {
    case ActionTypes.REGISTER_USER_REQUEST:
    case ActionTypes.LOGIN_USER_REQUEST:
    case ActionTypes.LOGOUT_USER_REQUEST:
    case ActionTypes.GET_USER_PROFILE_REQUEST:
    case ActionTypes.UPDATE_USER_PROFILE_REQUEST:
      return {
        ...state,
        actionsType: action.type,
        loading: true,
      };
    case ActionTypes.REGISTER_USER_FAILURE:
    case ActionTypes.LOGIN_USER_FAILURE:
    case ActionTypes.LOGOUT_USER_FAILURE:
    case ActionTypes.GET_USER_PROFILE_FAILURE:
    case ActionTypes.UPDATE_USER_PROFILE_FAILURE:
      return {
        ...state,
        actionsType: action.type,
        loading: false,
      };
    case ActionTypes.REGISTER_USER_SUCCESS:
    case ActionTypes.LOGIN_USER_SUCCESS:
    case ActionTypes.GET_USER_PROFILE_SUCCESS:
    case ActionTypes.UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        actionsType: action.type,
        loading: false,
        user: action.payload.data,
        token: action.payload.token,
        isLogin: true,
      };
    case ActionTypes.LOGOUT_USER_SUCCESS:
      return {
        ...state,
        actionsType: action.type,
        loading: false,
        user: {},
        token: "",
        isLogin: false,
      };
    case "CLEAR_USER_DATA":
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default usersReducer;
