import FormData from "form-data";
import { toast } from "react-toastify";
import ActionTypes from "./actionTypes";
import { serviceGetDetailCarts, serviceAddItemCarts, serviceRemoveItemCarts, serviceGetShipperCarts, serviceAddTransaction } from "../../../services";
import { AppDispatch, RootState } from "../../store";
// Configs
import { uri } from "../../../configs";
import { CartsType } from "../../../configs/datatype";

type status = "success" | "failed" | "error";

interface GetCarts {
  "session_request": string;
  "shopping_cart_id": string;
  "member_address_id"?: number;
}

interface AddUpdateItemCarts {
  "session_request": string;
  "shopping_cart_id": string;
  "product_id": number;
  "qty": number;
  "notes": string;
}

interface DelItemCarts {
  "session_request": string;
  "shopping_cart_id": string;
  "product_id": number;
}

interface ResponseGetCarts {
  status: status;
  message?: string;
  data: {
    "shopping_cart": CartsType;
    "payment_method": any;
  };
}

export const actionGetCarts = (reqdata: GetCarts) => {
  return async (dispatch: AppDispatch) => {
    dispatch({ type: ActionTypes.GET_CARTS_REQUEST });

    try {
      const fd = new FormData();

      fd.append("session_request", reqdata.session_request);
      fd.append("shopping_cart_id", reqdata.shopping_cart_id);

      const response: ResponseGetCarts = await serviceGetDetailCarts(fd);

      if (response.status === "failed" || response.status === "error") {
        return dispatch({
          type: ActionTypes.GET_CARTS_FAILURE,
          payload: {
            data: null,
            message: response.message,
          },
        });
      }

      const { items, shopping_car_id } = response.data.shopping_cart;

      for (let i = 0; i < items.length; i += 1) {
        items[i].product_photo = `${process.env.NEXT_PUBLIC_GRAHA_ASSET}/${uri.original["product-original"]}/${response.data.shopping_cart.items[i].product_photo}`;
      }

      const keyOfPaymentMethod = Object.keys(response.data.payment_method);

      const paymentAvailable = keyOfPaymentMethod.map((method: any) => { return response.data.payment_method[method]; });

      if (!localStorage.getItem("_cartsId")) {
        localStorage.setItem("_cartsId", shopping_car_id);
      }

      return dispatch({
        type: ActionTypes.GET_CARTS_SUCCESS,
        payload: {
          data: {
            cartsId: shopping_car_id,
            carts: items,
            paymentAvailable,
          },
        },
      });
    } catch (err: any) {
      return dispatch({
        type: ActionTypes.GET_CARTS_FAILURE,
        payload: {
          data: null,
          message: err.message,
        },
      });
    }
  };
};

export const actionAddItemCarts = (reqdata: AddUpdateItemCarts) => {
  return async (dispatch: AppDispatch) => {
    dispatch({ type: ActionTypes.ADD_CART_REQUEST });

    try {
      const fd = new FormData();
      fd.append("session_request", reqdata.session_request);
      fd.append("shopping_cart_id", reqdata.shopping_cart_id);
      fd.append("product_id", reqdata.product_id);
      fd.append("qty", reqdata.qty);
      fd.append("notes", reqdata.notes);

      const response: ResponseGetCarts = await serviceAddItemCarts(fd);

      if (response.status === "failed" || response.status === "error") {
        return dispatch({
          type: ActionTypes.ADD_CART_FAILURE,
          payload: {
            data: null,
            message: response.message,
          },
        });
      }

      const { items, shopping_car_id } = response.data.shopping_cart;

      if (!localStorage.getItem("_cartsId")) {
        localStorage.setItem("_cartsId", shopping_car_id);
      }

      for (let i = 0; i < items.length; i += 1) {
        items[i].product_photo = `${process.env.NEXT_PUBLIC_GRAHA_ASSET}/${uri.original["product-original"]}/${items[i].product_photo}`;
      }

      dispatch({
        type: ActionTypes.ADD_CART_SUCCESS,
        payload: {
          data: {
            cartsId: shopping_car_id,
            carts: items,
          },
        },
      });

      return dispatch(actionGetCarts({ session_request: reqdata.session_request, shopping_cart_id: response.data.shopping_cart.shopping_car_id }));
    } catch (err: any) {
      return dispatch({
        type: ActionTypes.ADD_CART_FAILURE,
        payload: {
          data: null,
          message: err.message,
        },
      });
    }
  };
};

export const actionUpdateItemCarts = (reqdata: AddUpdateItemCarts) => {
  return async (dispatch: AppDispatch) => {
    dispatch({ type: ActionTypes.UPDATE_CART_REQUEST });

    try {
      const fd = new FormData();

      fd.append("session_request", reqdata.session_request);
      fd.append("shopping_cart_id", reqdata.shopping_cart_id);
      fd.append("product_id", reqdata.product_id);
      fd.append("qty", reqdata.qty);
      fd.append("notes", reqdata.notes);

      const response: ResponseGetCarts = await serviceAddItemCarts(fd);

      if (response.status === "failed" || response.status === "error") {
        return dispatch({
          type: ActionTypes.UPDATE_CART_FAILURE,
          payload: {
            data: null,
            message: response.message,
          },
        });
      }

      const { items } = response.data.shopping_cart;

      for (let i = 0; i < items.length; i += 1) {
        response.data.shopping_cart.items[i].product_photo = `${process.env.NEXT_PUBLIC_GRAHA_ASSET}/${uri.original["product-original"]}/${items[i].product_photo}`;
      }

      return dispatch({
        type: ActionTypes.UPDATE_CART_SUCCESS,
        payload: {
          data: {
            carts: response.data.shopping_cart.items,
          },
        },
      });
    } catch (err: any) {
      return dispatch({
        type: ActionTypes.UPDATE_CART_FAILURE,
        payload: {
          data: null,
          message: err.message,
        },
      });
    }
  };
};

export const actionRemoveItemCarts = (data: DelItemCarts) => {
  return async (dispatch: AppDispatch) => {
    dispatch({ type: ActionTypes.DEL_CART_REQUEST });

    try {
      const fd = new FormData();

      fd.append("session_request", data.session_request);
      fd.append("shopping_cart_id", data.shopping_cart_id);
      fd.append("product_id", data.product_id);

      const response: ResponseGetCarts = await serviceRemoveItemCarts(fd);

      if (response.status === "failed" || response.status === "error") {
        return dispatch({
          type: ActionTypes.DEL_CART_FAILURE,
          payload: {
            data: null,
            message: response.message,
          },
        });
      }

      const { items } = response.data.shopping_cart;

      for (let i = 0; i < items.length; i += 1) {
        response.data.shopping_cart.items[i].product_photo = `${process.env.NEXT_PUBLIC_GRAHA_ASSET}/${uri.original["product-original"]}/${items[i].product_photo}`;
      }

      return dispatch({
        type: ActionTypes.DEL_CART_SUCCESS,
        payload: {
          data: {
            carts: response.data.shopping_cart.items,
          },
        },
      });
    } catch (err: any) {
      return dispatch({
        type: ActionTypes.DEL_CART_FAILURE,
        payload: {
          data: null,
          message: err.message,
        },
      });
    }
  };
};
