import FormData from "form-data";
import { API_GRAHA } from "./http";

export const serviceRegister = async (reqdata: FormData) => {
  const enpoint = "/authentication/registration";

  const request = await API_GRAHA.post(enpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceLogin = async (reqdata: FormData) => {
  // const enpoint = "/authentication/login";
  const enpoint = "/authentication/login-password";

  const request = await API_GRAHA.post(enpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceLogout = async (reqdata: FormData) => {
  const enpoint = "/authentication/logout";

  const request = await API_GRAHA.post(enpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceForgotPassword = async (reqdata: FormData) => {
  const enpoint = "/authentication/forgot-password";

  const request = await API_GRAHA.post(enpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceGetSliderHome = async () => {
  const endpoint = "/slider/lists";

  const request = await API_GRAHA.get(endpoint);
  const response = request.data;

  return response;
};

export const serviceGetIdAddressForProduct = async (reqdata: FormData) => {
  const endpoint = "/regional/get-current-location";

  // Server side need specific header content type
  // const request = await API_GRAHA.post(endpoint, reqdata, {
  //   headers: {
  //     "Content-Type": `multipart/form-data; boundary=${reqdata.getBoundary()}`,
  //   },
  // });

  const request = await API_GRAHA.post(endpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceGetBaseUrl = async () => {
  const endpoint = "/home/base-url";

  const request = await API_GRAHA.get(endpoint);
  const response = request.data;

  return response;
};

export const serviceGetProductsByLocation = async (reqdata: FormData) => {
  const endpoint = "/home/dashboard";

  const request = await API_GRAHA.post(endpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceGetListProducts = async (reqdata: FormData) => {
  const endpoint = "/product/search-product";

  const request = await API_GRAHA.post(endpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceGetDetailProduct = async (reqdata: FormData) => {
  const endpoint = "/product/product-detail-for-transaction";

  const request = await API_GRAHA.post(endpoint, reqdata);
  const response = {
    status: request.data.status,
    data: request.data.data,
  };

  response.data.product.information = JSON.parse(response.data.product.information);
  response.data.shop.data = JSON.parse(response.data.shop.data);

  return response;
};

export const serviceGetStoreDetail = async (reqdata: FormData) => {
  const enpoint = "/product/shop-detail";

  const request = await API_GRAHA.post(enpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceGetProductsInStore = async (reqdata: FormData) => {
  const enpoint = "/product/get-all-product-by-shop";

  const request = await API_GRAHA.post(enpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceGetCategoriesForProduct = async () => {
  const endpoint = "/product/get-all-category";

  const request = await API_GRAHA.get(endpoint);
  const response = request.data;

  return response;
};

export const serviceGetListTransaction = async (reqdata: FormData) => {
  const enpoint = "/transaction-food/lists";

  const request = await API_GRAHA.post(enpoint, reqdata);
  const response: any = {
    status: request.data.status,
    data: request.data.data.lists,
  };

  for (let i = 0; i < response.data.length; i += 1) {
    response.data[i].price_description = JSON.parse(response.data[i].price_description);
    response.data[i].transaction_description = JSON.parse(response.data[i].transaction_description);
    response.data[i].payment_description = JSON.parse(response.data[i].payment_description);
    response.data[i].delivery_address_description = JSON.parse(response.data[i].delivery_address_description);
    response.data[i].shipment_method_detail_data = JSON.parse(response.data[i].shipment_method_detail_data);
  }

  return response;
};

export const serviceGetDetailTransaction = async (reqdata: FormData) => {
  const enpoint = "/transaction-food/detail";

  const request = await API_GRAHA.post(enpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceUpdateTransactionStatus = async (reqdata: FormData) => {
  const enpoint = "/transaction-food/update-status-transaction";

  const request = await API_GRAHA.post(enpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceCancelTransaction = async (reqdata: FormData) => {
  const enpoint = "/transaction-food/cancel-by-buyer";

  const request = await API_GRAHA.post(enpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceGetAddresses = async (reqdata: FormData) => {
  const endpoint = "/profile/address-list";

  const request = await API_GRAHA.post(endpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceAddAddress = async (reqdata: FormData) => {
  const endpoint = "/profile/address-add";

  const request = await API_GRAHA.post(endpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceUpdateAddress = async (reqdata: FormData) => {
  const endpoint = "/profile/address-update";

  const request = await API_GRAHA.post(endpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceSetDefaultAddress = async (reqdata: FormData) => {
  const endpoint = "/profile/address-set-default";

  const request = await API_GRAHA.post(endpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceDeleteAddress = async (reqdata: FormData) => {
  const endpoint = "/profile/address-delete";

  const request = await API_GRAHA.post(endpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceGetRegions = async (reqdata: FormData) => {
  const endpoint = "/regional/get-data";

  const request = await API_GRAHA.post(endpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceAddItemCarts = async (reqdata: FormData) => {
  const endpoint = "/shopping-cart/add-item";

  const request = await API_GRAHA.post(endpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceRemoveItemCarts = async (reqdata: FormData) => {
  const endpoint = "/shopping-cart/remove-item";

  const request = await API_GRAHA.post(endpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceGetDetailCarts = async (reqdata: FormData) => {
  const endpoint = "/shopping-cart/detail";

  const request = await API_GRAHA.post(endpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceGetShipperCarts = async (reqdata: FormData) => {
  const endpoint = "/shopping-cart/get-rates";

  const request = await API_GRAHA.post(endpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceAddTransaction = async (reqdata: FormData) => {
  const endpoint = "/shopping-cart/submit";

  const request = await API_GRAHA.post(endpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceGetListsBank = async (reqdata: FormData) => {
  const endpoint = "/bank-account/list-bank";

  const request = await API_GRAHA.post(endpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceGetAccountBank = async (reqdata: FormData) => {
  const endpoint = "/bank-account/detail";

  const request = await API_GRAHA.post(endpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceCreateAccountBank = async (reqdata: FormData) => {
  const endpoint = "/bank-account/create";

  const request = await API_GRAHA.post(endpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceUpdateAccountBank = async (reqdata: FormData) => {
  const endpoint = "/bank-account/update";

  const request = await API_GRAHA.post(endpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceGetListWithdraw = async (reqdata: FormData) => {
  const endpoint = "/withdraw/list";

  const request = await API_GRAHA.post(endpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceGetDetailWithdraw = async (reqdata: FormData) => {
  const endpoint = "/withdraw/detail";

  const request = await API_GRAHA.post(endpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceGetWithdrawAvailability = async (reqdata: FormData) => {
  const endpoint = "/withdraw/get-availability";

  const request = await API_GRAHA.post(endpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceAddWithdraw = async (reqdata: FormData) => {
  const endpoint = "/withdraw/request";

  const request = await API_GRAHA.post(endpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceCancelWithdraw = async (reqdata: FormData) => {
  const endpoint = "/withdraw/cancel";

  const request = await API_GRAHA.post(endpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceGetReviewByStore = async (reqdata: FormData) => {
  const enpoint = "/review/lists";

  const request = await API_GRAHA.post(enpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceGetProfileDetail = async (reqdata: FormData) => {
  const enpoint = "/profile/profile-detail";

  const request = await API_GRAHA.post(enpoint, reqdata);
  const response = request.data;

  return response;
};

export const serviceUpdateProfile = async (reqdata: FormData) => {
  const enpoint = "/profile/profile-update";

  const request = await API_GRAHA.post(enpoint, reqdata);
  const response = request.data;

  return response;
};
