import { routesName } from "./app";
import { RoutesDataTypes } from "../datatype";

const APP_ROUTE: Array<RoutesDataTypes> = [
  {
    name: routesName.HOME,
    title: "Beranda",
    path: "/",
    placement: ["header"],
  },
  {
    name: routesName.PRODUCTS,
    title: "Produk",
    path: "/produk",
    placement: ["header"],
  },
  {
    name: routesName.SHOPS,
    title: "Toko",
    path: "/toko",
    placement: [],
  },
  {
    name: routesName.CARTS,
    title: "Keranjang Belanja",
    path: "/keranjang-belanja",
    placement: [],
  },
  {
    name: routesName.TRANSACTION,
    title: "Pembelian",
    path: "/transaksi",
    placement: ["authmenu", "usermenu"],
  },
  {
    name: routesName.PAYMENT,
    title: "Pembayaran",
    path: "/transaksi/pembayaran",
    placement: [],
  },
  {
    name: routesName.USER,
    title: "Pengguna",
    path: "/user",
    placement: [],
  },
  {
    name: routesName.LOGIN,
    title: "Login",
    path: "/user/login",
    placement: [],
  },
  {
    name: routesName.REGISTER,
    title: "Register",
    path: "/user/register",
    placement: [],
  },
  {
    name: routesName.OTP,
    title: "Verifikasi",
    path: "/user/otp",
    placement: [],
  },
  {
    name: routesName.ADDRESS,
    title: "Alamat",
    path: "/user/alamat",
    placement: ["usermenu"],
  },
  {
    name: routesName.REVIEW,
    title: "Ulasan",
    path: "/user/ulasan",
    placement: [],
  },
  {
    name: routesName.DEPOSIT,
    title: "Saldo",
    path: "/user/saldo",
    placement: ["usermenu"],
  },
  {
    name: routesName.PROFILE,
    title: "Profil",
    path: "/user/profil",
    placement: ["authmenu", "usermenu"],
  },
  {
    name: routesName.ABOUT,
    title: "Tentang Kami",
    path: "/tentang-kami",
    placement: ["header"],
  },
  {
    name: routesName.FAQ,
    title: "FAQ",
    path: "/tentang-kami/tanya-jawab",
    placement: ["header"],
  },
  {
    name: routesName.JOIN_MERCHANT,
    title: "Toko",
    path: "/tentang-kami/gabung-toko",
    placement: ["footer"],
  },
  {
    name: routesName.JOIN_INVESTOR,
    title: "Investor",
    path: "/tentang-kami/gabung-investor",
    placement: ["footer"],
  },
  {
    name: routesName.PRIVACY,
    title: "Kebijakan Privasi",
    path: "/tentang-kami/kebijakan-privasi",
    placement: ["footer"],
  },
  {
    name: routesName.TERM,
    title: "Syarat Ketentuan",
    path: "/tentang-kami/syarat-ketentuan",
    placement: ["footer"],
  },
  // {
  //   name: routesName.CONTACT,
  //   title: "Kontak",
  //   path: "/tentang-kami/kontak",
  //   placement: ["footer"],
  // },
  {
    name: routesName.DOWNLOAD,
    title: "Download",
    path: "/download",
    placement: [],
  },
];

export default APP_ROUTE;
