import requestHttp from "./network";
import { __exco, __grahapi } from "../../configs/constants";
import { decoded } from "../../utils";

// eslint-disable-next-line no-underscore-dangle
const __xCo = decoded(__exco);
const __xMb = "pk.eyJ1IjoiZm9vZGl6bWFydCIsImEiOiJja3czYnlzeG04amdnMm5zMTdlaDF5YmhwIn0.xdCSw6vu7Es8uzQTxjn8OA";

// export const API_BACKEND = requestHttp(
//   process.env.API_BACKEND_URL,
//   "default",
//   "bearer"
// );

export const API_GRAHA = requestHttp(
  decoded(__grahapi),
  "default",
  "basic",
  __xCo,
);

export const API_CHAT = requestHttp(
  `${process.env.NEXT_PUBLIC_GRAHA_CHAT_URL}/${process.env.NEXT_PUBLIC_GRAHA_CHAT_VERSION}`,
  "default",
  "basic",
  __xCo,
);

export const API_GOOGLEMAP = requestHttp(
  "https://maps.googleapis.com/maps/api",
  "other",
  "apikey",
);

export const API_MAPBOX = requestHttp(
  "https://api.mapbox.com",
  "other",
  "mapbox",
  __xMb,
);

export const API_GOOGLESTORAGE = requestHttp(
  "https://firebasestorage.googleapis.com/v0/b",
  "other",
  "apikey",
);
