enum ActionTypes {
  REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS",
  REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST",
  REGISTER_USER_FAILURE = "REGISTER_USER_FAILURE",

  LOGIN_USER_REQUEST = "LOGIN_USER_REQUEST",
  LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS",
  LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE",

  LOGOUT_USER_REQUEST = "LOGOUT_USER_REQUEST",
  LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS",
  LOGOUT_USER_FAILURE = "LOGOUT_USER_FAILURE",

  GET_USER_PROFILE_REQUEST = "GET_USER_PROFILE_REQUEST",
  GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS",
  GET_USER_PROFILE_FAILURE = "GET_USER_PROFILE_FAILURE",

  UPDATE_USER_PROFILE_REQUEST = "UPDATE_USER_PROFILE_REQUEST",
  UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS",
  UPDATE_USER_PROFILE_FAILURE = "UPDATE_USER_PROFILE_FAILURE",
}

export default ActionTypes;
