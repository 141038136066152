enum ActionTypes {
  GET_ADDRESS_LISTS_SUCCESS = "GET_ADDRESS_LISTS_SUCCESS",
  GET_ADDRESS_LISTS_REQUEST = "GET_ADDRESS_LISTS_REQUEST",
  GET_ADDRESS_LISTS_FAILURE = "GET_ADDRESS_LISTS_FAILURE",

  ADD_ADDRESS_REQUEST = "ADD_ADDRESS_REQUEST",
  ADD_ADDRESS_SUCCESS = "ADD_ADDRESS_SUCCESS",
  ADD_ADDRESS_FAILURE = "ADD_ADDRESS_FAILURE",

  UPDATE_ADDRESS_REQUEST = "UPDATE_ADDRESS_REQUEST",
  UPDATE_ADDRESS_SUCCESS = "UPDATE_ADDRESS_SUCCESS",
  UPDATE_ADDRESS_FAILURE = "UPDATE_ADDRESS_FAILURE",

  DEL_ADDRESS_REQUEST = "DEL_ADDRESS_REQUEST",
  DEL_ADDRESS_SUCCESS = "DEL_ADDRESS_SUCCESS",
  DEL_ADDRESS_FAILURE = "DEL_ADDRESS_FAILURE",

  SET_ADDRESSS_DEFAULT_REQUEST = "SET_ADDRESS_DEFAULT_REQUEST",
  SET_ADDRESSS_DEFAULT_SUCCESS = "SET_ADDRESS_DEFAULT_SUCCESS",
  SET_ADDRESSS_DEFAULT_FAILURE = "SET_ADDRESS_DEFAULT_FAILURE",
}

export default ActionTypes;
