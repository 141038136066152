import { useEffect, useCallback } from "react";
import type { AppProps } from "next/app";
import Head from "next/head";
import Script from "next/script";
import { NextRouter, useRouter } from "next/router";
import { Provider } from "react-redux";
import Cookies from "js-cookie";
import FormData from "form-data";
import { ToastContainer, toast } from "react-toastify";
import * as Sentry from "@sentry/nextjs";
import { store, RootState } from "../reduxs";
import ActionTypes from "../reduxs/actions/users/actionTypes";
import { uri, routesName } from "../configs";
import { decoded, setRoute, useWindowDimensions } from "../utils";

import { serviceGetProfileDetail } from "../services";

// Moment locale
import "moment/locale/id";

// Styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import "react-dates/lib/css/_datepicker.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "../styles/icofont.min.css";
import "../styles/hcoffcanvas.css";
import "../styles/globals.css";

function MyApp({ Component, pageProps }: AppProps) {
  const router: NextRouter = useRouter();
  const { width } = useWindowDimensions();

  const getProfileDetail = useCallback(async (token) => {
    store.dispatch({ type: ActionTypes.GET_USER_PROFILE_REQUEST });

    const dataSubmit = {
      session_request: token,
    };

    try {
      const fd = new FormData();
      fd.append("session_request", dataSubmit.session_request);

      const response = await serviceGetProfileDetail(fd);

      if (response.status === "failed" || response.status === "error") {
        Sentry.setContext("User Detail", dataSubmit);
        Sentry.captureException(new Error(`Failed to fetch user :: ${response.message}`), {
          tags: {
            section: "users",
          },
        });

        Cookies.remove("_xSe");
        sessionStorage.setItem("_xLo", JSON.stringify(false));
        localStorage.removeItem("ufk");

        store.dispatch({ type: ActionTypes.GET_USER_PROFILE_FAILURE, payload: { data: null, message: response.message } });

        router.push(setRoute(routesName.HOME));

        return;
      }

      const profile = { ...response.data.profile };

      profile.avatar = `${process.env.NEXT_PUBLIC_GRAHA_ASSET}/${uri.original.profile}/${response.data.profile.avatar}`;
      profile.avatar_file = response.data.profile.avatar;

      store.dispatch({ type: ActionTypes.GET_USER_PROFILE_SUCCESS, payload: { data: profile, token } });
    } catch (err: any) {
      Sentry.setContext("User Detail", dataSubmit);
      Sentry.captureException(new Error(`Failed to fetch user :: ${err.message}`), {
        tags: {
          section: "users",
        },
      });

      if (process.env.NEXT_PUBLIC_ENV === "development") {
        toast.error(err.message);
        store.dispatch({ type: ActionTypes.GET_USER_PROFILE_FAILURE, payload: { data: null, message: err.message } });

        return;
      }

      toast.error("An error occurred while logging in");
      store.dispatch({ type: ActionTypes.GET_USER_PROFILE_FAILURE, payload: { data: null, message: err.message } });
    }
  }, []);

  useEffect(() => {
    if (width > 0 && width < 1140 && router.pathname !== setRoute(routesName.DOWNLOAD)) {
      router.push(setRoute(routesName.DOWNLOAD));
    }
  }, [width]);

  useEffect(() => {
    const isLogin = JSON.parse(sessionStorage.getItem("_xLo")!);
    const _xSe = Cookies.get("_xSe");

    const state: RootState = store.getState();
    const { conversations } = state.rdcchats;

    if (!isLogin && !_xSe) {
      sessionStorage.setItem("_xLo", JSON.stringify(false));
      localStorage.removeItem("ufk");

      if (conversations?.length > 0) store.dispatch({ type: "CLEAR_CHAT" });
    }

    if (_xSe) {
      sessionStorage.setItem("_xLo", JSON.stringify(true));

      getProfileDetail(decoded(_xSe || ""));
    }
  }, []);

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no, minimum-scale=1" />
        <meta name="theme-color" content="#f52d56" />
        <meta name="sentry-trace" content="{{ span.toSentryTrace() }}" />
        <link rel="icon" href="/favicon.ico" />

        {/* Bootstrap css */}
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css"
          integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l"
          crossOrigin="anonymous"
        />
      </Head>

      {/* Call Bootstrap JS */}
      <Script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js"
        integrity="sha384-MrcW6ZMFYlzcLA8Nl+NtUVF0sA7MsXsP1UyJoMp4YLEuNSfAP+JcXn/tWtIaxVXM"
        crossOrigin="anonymous"
      />

      {process.env.NEXT_PUBLIC_ENV === "production" && (
        <Script
          id="__hotjar_script__"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:2908812,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
              })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `,
          }}
        />
      )}

      <Script type="text/javascript" src="/script/hc-offcanvas-nav.js" strategy="afterInteractive" />
      <Script type="text/javascript" src="/script/template.js" strategy="lazyOnload" />

      <Provider store={store}>
        <Component {...pageProps} />

        <ToastContainer autoClose={3000} theme="light" />
      </Provider>
    </>
  );
}

export default MyApp;
