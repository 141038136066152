import { API_CHAT } from "./http";
// Configs
import { ConversationsAdd, ConversationsParams, MessagesAdd, MessagesParams } from "../configs/datatype";

export const serviceAddConversations = async (data: ConversationsAdd) => {
  const endpoint = "/conversations/add";

  const request = await API_CHAT.post(endpoint, data);
  const response = request.data;

  return response;
};

export const serviceGetConversations = async (params: ConversationsParams) => {
  const endpoint = "/conversations";

  const request = await API_CHAT.get(endpoint, { params });
  const response = request.data;

  return response;
};

export const serviceAddMessages = async (data: MessagesAdd) => {
  const endpoint = "/messages/add";

  const request = await API_CHAT.post(endpoint, data);
  const response = request.data;

  return response;
};

export const serviceGetMessages = async (params: MessagesParams) => {
  const endpoint = "/messages";

  const request = await API_CHAT.get(endpoint, { params });
  const response = request.data;

  return response;
};

export const serviceUpdateMessages = async (data: MessagesAdd, id: number) => {
  const endpoint = `/messages/${id}`;

  const request = await API_CHAT.put(endpoint, data);
  const response = request.data;

  return response;
};
