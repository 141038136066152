export const __exco: string = "UmpCUFJDRmFUVFF4TWxRNk16UXdNelEzTnpjNU5UVTNOamcwTURFME1EY3lNRFV3T1RRNU5URTRPRGszTnpRME5EWXhNdz0";

// export const __exgo: string = "QUl6YVN5Q3NZQzZGbklxaFN4YnVrQTEzUmJ3QUZMd1d0Y1FCR1pZ";
export const __exgo: string = "QUl6YVN5Q2ZOZVVmbHl4STJScUtvNWpJdTVrWlJoQ0h2NERQSjJB";

export const __exch: string = "ZDZiODk1NmU5NzA0NWM2YTI2YTk=";

export const __grahapi: string = "aHR0cHM6Ly9mb29kaS5vdGl6YS5jb20vYXBpdjE=";

export const APP_BASEURL: string = "https://foodizmart.com";

export enum fbsc {
  _ekey = "QUl6YVN5QmVaNDljMlZ0VW55WGRGU2tDU0JFSmRtR0xJN2JWNkVN",
  _edomain = "Zm9vZGl6bWFydC1iMTJlMS5maXJlYmFzZWFwcC5jb20=",
  _proid = "Zm9vZGl6bWFydC1iMTJlMQ==",
  _strbuct = "Zm9vZGl6bWFydC1iMTJlMS5hcHBzcG90LmNvbQ==",
  _msgid = "NjUzODU2MTQ3NzQ3",
  _apps = "MTo2NTM4NTYxNDc3NDc6d2ViOjM4NThmZWU3NWQ3MjQzMjVmZDI0OTY=",
  _messid = "Ry0zSFI3UjhMREVS",
  pubkey = "QkJ4SEpQaEtKX0RXWVdSZkR2Q3RKNjg3VmxDczFtYURkZDJhUU83M0dxZ2dpZWVrNUpFd21Nb1dJT3JFSjExbVEzMExqSm5NZk5tY3BjNlN0dTFfdmVz",
}

export enum routesName {
  HOME = "home",
  PRODUCTS = "products",
  SHOPS = "shops",
  CARTS = "carts",
  TRANSACTION = "transaction",
  PAYMENT = "payment",
  FAQ = "faq",
  USER = "user",
  LOGIN = "login",
  REGISTER = "register",
  OTP = "otp",
  ADDRESS = "address",
  REVIEW = "review",
  DEPOSIT = "deposit",
  PROFILE = "profile",
  ABOUT = "about",
  JOIN_MERCHANT = "joinmerchant",
  JOIN_INVESTOR = "joininvestor",
  PRIVACY = "privacy",
  TERM = "terms",
  CONTACT = "contact",
  DOWNLOAD = "download",
}

export const messagesDataShowLimit: number = 10;
