// Configs
import { routes } from "../configs";
import { RoutesDataTypes } from "../configs/datatype";

export const encoded = (value: string) => {
  return Buffer.from(value).toString("base64");
};

export const decoded = (value: string) => {
  return Buffer.from(value, "base64").toString();
};

export const setRoute = (routeName: string) => {
  return routes.find((route: RoutesDataTypes) => { return route.name === routeName; })?.path as string;
};

export const navigation = (position: string) => {
  return routes.filter((route: RoutesDataTypes) => { return route.placement?.includes(position); });
};

export const navigationSingle = (routeName: string) => {
  return routes.filter((route: RoutesDataTypes) => { return route.name === routeName; });
};

export const makeRandomID = (length: number) => {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;

  let id = "";
  let i = 0;
  for (i; i < length; i += 1) {
    id += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return id;
};

export const filterSort = (data: any, filter: any, sort: any) => {
  let result = data;

  if (filter) {
    result = result.filter((item: any) => {
      const keys = Object.keys(filter);
      let isValid = true;
      for (let i = 0; i < keys.length; i += 1) {
        if (filter[keys[i]] !== item[keys[i]]) {
          isValid = false;
        }
      }
      return isValid;
    });
  }

  if (sort) {
    const keys = Object.keys(sort);
    result.sort((a: any, b: any) => {
      if (sort[keys[0]] === "asc") {
        return a[keys[1]] - b[keys[1]];
      }
      return b[keys[1]] - a[keys[1]];
    });
  }

  return result;
};
