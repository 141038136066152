import ActionTypes from "../actions/address/actionTypes";
import { AddressItem } from "../../configs/datatype";

interface AddressReducer {
  loading: boolean;
  isEmpty: boolean;
  addressDefault: Partial<AddressItem>;
  addressLists: Array<AddressItem>;
}

interface ClearState {
  type: "CLEAR_ADDRESS";
  payload: AddressReducer;
}

interface LoadingState {
  type: ActionTypes.GET_ADDRESS_LISTS_REQUEST
        | ActionTypes.ADD_ADDRESS_REQUEST
        | ActionTypes.UPDATE_ADDRESS_REQUEST
        | ActionTypes.DEL_ADDRESS_REQUEST
        | ActionTypes.SET_ADDRESSS_DEFAULT_REQUEST;
}

interface SetAddressDefaultAction {
  type: ActionTypes.SET_ADDRESSS_DEFAULT_SUCCESS;
  payload: {
    data: AddressReducer["addressDefault"];
  };
}

interface GetAddressListsAction {
  type: ActionTypes.GET_ADDRESS_LISTS_SUCCESS;
  payload: {
    data: AddressReducer["addressLists"];
  };
}

type AddressAction = LoadingState | ClearState | SetAddressDefaultAction | GetAddressListsAction;

const initialState: AddressReducer = {
  loading: false,
  isEmpty: false,
  addressDefault: {},
  addressLists: [],
};

const addressReducer = (state: AddressReducer = initialState, action: AddressAction) => {
  switch (action.type) {
    case ActionTypes.GET_ADDRESS_LISTS_REQUEST:
    case ActionTypes.ADD_ADDRESS_REQUEST:
    case ActionTypes.UPDATE_ADDRESS_REQUEST:
    case ActionTypes.DEL_ADDRESS_REQUEST:
    case ActionTypes.SET_ADDRESSS_DEFAULT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GET_ADDRESS_LISTS_SUCCESS:
      return {
        ...state,
        loading: false,
        isEmpty: action.payload.data.length === 0,
        addressLists: action.payload.data,
      };
    case ActionTypes.SET_ADDRESSS_DEFAULT_SUCCESS:
      return {
        ...state,
        loading: false,
        addressDefault: action.payload.data,
      };
    case "CLEAR_ADDRESS":
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default addressReducer;
